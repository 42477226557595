import {
  CEHECK_ENTER_NUMBER,
  ENTER_OTP,
  FETCH_CUISINES,
  FORGET_PASSWORD,
  LOADING_START,
  LOADING_STOP,
  LOGIN_DATA,
  RESTAURANT_LIST,
  SIGN_UP,
  UPDATE_ONBOARDING_STATE,
  USER_LOGIN,
} from "../actionTypes/authTypes";

export function companyInputChange(payload) {
  return { type: CEHECK_ENTER_NUMBER, payload };
}
export function fetchCuisines(payload) {
  return { type: FETCH_CUISINES, payload };
}
export function loginPost(payload) {
  return { type: LOGIN_DATA, payload };
}

export function enterOtp(payload) {
  return { type: ENTER_OTP, payload };
}
export function signUpAccount(payload) {
  return { type: SIGN_UP, payload };
}
export function userLogin(payload) {
  return { type: USER_LOGIN, payload };
}
export function restaurantList(payload) {
  return { type: RESTAURANT_LIST, payload };
}
export function forgetPassword(payload) {
  return { type: FORGET_PASSWORD, payload };
}
export function dispatchLoadingStart() {
  return { type: LOADING_START };
}

export function dispatchLoadingStop() {
  return { type: LOADING_STOP };
}

export function updateOnboardingState(payload) {
  return { type: UPDATE_ONBOARDING_STATE, payload };
}
