import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { placeholderHandler } from "../../helpers/help";
import AddCartItem from "../modals/AddCartItem";
import Restauranttime from "../modals/Restauranttime";
import TakeoutOnly from "../common/TakeoutOnly";

const RestaurantMenu = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [restoDetails, setRestoDetails] = useState({});
  const navigate = useNavigate();
  const { latitude, longitude, outletId } = location.state.item.outlet[0];
  const [selectedItem, setSelectedItem] = useState({});
  const [openRestTimeModal, setOpenRestTime] = useState({
    state: false,
    data: "",
  });
  const handleShow = (data) => setOpenRestTime({ state: true, data: data });
  const AddItem = () => {
    navigate("/addmenuitem");
  };
  // const checkOut = () => {
  //   navigate("/checkout")
  // }

  const restaurantDetails = (e) => {
    http(
      {
        method: "POST",
        url: `listDishes`,
        isSecure: true,
        body: {
          latitude,
          longitude,
          outletId,
        },
      },
      (res) => {
        setRestoDetails(res);
      }
    );
  };
  useEffect(() => {
    restaurantDetails();
  }, []);

  const backPage = () => {
    navigate("/restaurant");
  };

  return (
    <>
      <main>
        <div className="restaurant_inner_bg">
          <div className="cus_container">
            <h1 className="head1 text-center">Restaurants</h1>
            <form>
              <div className="inputGroup text-center">
                <input
                  type="text"
                  className="input_control input-inside-img bg-white "
                />
              </div>
            </form>
          </div>
        </div>
      </main>
      <div className="restaurantsNear_menu">
        <div className="cus_container">
          <div className="d-flex">
            <div className="item_filter_box">
              <h4 className="head4 bor_lineR">All Details</h4>
              <ul>
                <li>
                  <button type="button" className="filterbtn active flex-row">
                    <img
                      src="./assets/img/menu_filter.svg"
                      alt="filter"
                      className="filter me-2 d-flex align-items-center imgw_s"
                    />
                    <span className="accor-titile">Menu</span>
                  </button>
                </li>
                <li>
                  <button type="button" className="filterbtn flex-row">
                    <img
                      src="./assets/img/star_Accor.svg"
                      alt="filter"
                      className="filter me-2 d-flex align-items-center"
                    />
                    <span className="accor-titile">Reviews</span>
                  </button>
                </li>
                <li>
                  <button type="button" className="filterbtn flex-row">
                    <img
                      src="./assets/img/info_icon.svg"
                      alt="filter"
                      className="filter me-2 d-flex align-items-center"
                    />
                    <span className="accor-titile">Restaurants Info</span>
                  </button>
                </li>
              </ul>
              <div className="line-content-middle my-3"></div>
              <div className=" px-3 ">
                <div className="filter-title ">By Price</div>
                <div className="rang-box  py-2">
                  <img
                    src="./assets/img/multiple_range.png"
                    alt="rang"
                    className="w-100"
                  />
                </div>
                <div className="filter-title Price-ft">
                  Price: <span>$0</span> - <span>$100</span>
                </div>
              </div>

              <div className="d-lg-flex align-items-center mt-5 px-3">
                <button className="cusbtn me-3 w-100">Apply</button>
                <button className="hoverbtn w-100 mt-3 mt-lg-0">
                  Clear All
                </button>
              </div>
              <img
                src="./assets/img/filter_f-img.png"
                alt="food img"
                className="footerimg-filter"
              />
            </div>
            <div className="restaurant_view_all res_menu_screen">
              <a className="arrow_back d-inline" onClick={() => backPage()}>
                <img src="./assets/img/arrowBack.svg" alt="arrow back" />
                Back
              </a>
              <h4 className="head4 mt-4">Restaurants </h4>
              <div className="view_data_resaurant">
                <div className="grid_item_single">
                  <div className="restaurants_container">
                    <div className="restaurants_group">
                      <div className="restaurants_item">
                        <div className="res_img">
                          <img
                            onError={placeholderHandler}
                            src={restoDetails.image}
                            alt="restaurant"
                            className="restaurant-img"
                          />
                        </div>
                        <div className="res_content p-2">
                          <div className="my-2 d-flex align-items-center justify-content-between">
                            <h6 className="head6">
                              {restoDetails.outlet_name}
                            </h6>

                            {restoDetails.emergency_close === 0 ? (
                              <div className="btn_res open">Open</div>
                            ) : (
                              <div className="btn_res close">Close</div>
                            )}
                          </div>
                          <p className="txt nowrap mt-2 mb-0">
                            {restoDetails &&
                              restoDetails.foodTag?.map((tag, index) => {
                                return (
                                  <span className="me-2" key={"tag" + index}>
                                    {tag.itemName},
                                  </span>
                                );
                              })}
                          </p>
                          <div className="ratting_sec d-flex align-items-center justify-content-end">
                            <div className="review_start">
                              <Rating
                                initialValue={restoDetails.averageReview}
                                readonly
                              />
                            </div>
                          </div>
                          {/* {
                            restoDetails?.restaurant_business_day?.length > 0 > 0 ?
                              restoDetails?.restaurant_business_day?.map((item, index) => (
                                <div className="dayShow-open" key={index}>

                                  <div className="cirBox">{item?.day}</div>

                                </div>
                              )) : 'No data found'
                          } */}
                          <div className="dayShow-open">
                            {restoDetails?.restaurant_business_day?.length >
                              0 >
                              0
                              ? restoDetails?.restaurant_business_day?.map(
                                (item, index) => (
                                  <div key={item.day} className="cirBox">
                                    {" "}
                                    {item?.day.toUpperCase().substring(0, 2)}
                                  </div>
                                )
                              )
                              : "No data found"}
                            <button
                              className="cirBox"
                              onClick={() =>
                                handleShow(
                                  restoDetails?.restaurant_business_day,
                                  true
                                )
                              }
                            >
                              <img
                                src="./assets/img/time.png"
                                alt="time"
                                className="w-100"
                              />
                            </button>
                          </div>
                          {/* <p className="txt mb-2 d-flex align-items-center blackC">
                            <img
                              src="./assets/img/canlendar.svg"
                              alt="start"
                              className="me-2"
                            />
                            {moment().format("dddd")} - Sunday
                          </p> */}
                          {/* <p className="txt mb-2 d-flex align-items-center blackC">
                            <img
                              src="./assets/img/time_remind.svg"
                              alt="start"
                              className="me-2"
                            /> */}
                          {/* {moment(
                              restoDetails?.restaurant_business_day?.from_time,
                              "HH:mm"
                            ).format("hh:mm")}
                            -
                            {moment(
                              restoDetails?.restaurant_business_day?.to_time,
                              "HH:mm"
                            ).format("hh:mm")} */}
                          {/* </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="offer_box mt-4">
                  <div className="d-flex flex-lg-row flex-column">
                    <div className="white_box">
                      <div className="offer-img">
                        <img
                          src="./assets/img/offer_img1.png"
                          alt=""
                          className="offer-img_icon"
                        />
                      </div>
                      <div className="offer-content">
                        <h6 className="head6">Flat 20% OFF</h6>
                        <p className="txt mb-0">
                          Use Code SUPERSAVER | Above $20.00
                        </p>
                      </div>
                    </div>
                    <div className="white_box mt-4 mt-lg-0">
                      <div className="offer-img">
                        <img
                          src="./assets/img/offer_img2.png"
                          alt=""
                          className="offer-img_icon"
                        />
                      </div>
                      <div className="offer-content">
                        <h6 className="head6">60% OFF up to $15</h6>
                        <p className="txt mb-0">
                          Use Code HAPPYFOOD | Above $20.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product_view_dis mt-5">
                  <div className="d-flex">
                    <div className="col-left me-4">
                      <h4 className="head4 mb-4">Menu </h4>
                      {restoDetails?.dishes?.map((item, index) => {
                        return (
                          <div
                            key={`category-id-${item.categoryId}`}
                            className="mt-4"
                          >
                            <h5 className="head5">{item.categoryName}</h5>
                            <div className="product_dis">
                              {item?.categoryValues?.map((sub_Item, index) => {
                                return (
                                  <div
                                    key={`dish-id-${sub_Item.dishId}`}
                                    className="white_box pointer"
                                    onClick={() => {
                                      setShow(true);
                                      setSelectedItem(sub_Item);
                                    }}
                                  >
                                    <h6 className="head6 mb-1 blackC">
                                      {sub_Item.dishName}
                                      {
                                        sub_Item.isVeg === 0 ? (
                                          <img
                                            src="./assets/img/veg_icon.svg"
                                            alt="veg img"
                                            className="ms-2"
                                          />
                                        ) : null
                                        // <img
                                        //     src="./assets/img/visa_icon.svg"
                                        //     alt="veg img"
                                        //     className='ms-2' />
                                      }
                                    </h6>
                                    <h6 className="head6 mb-2 blackC">
                                      ${sub_Item.price}
                                    </h6>
                                    <p className="txt mb-0 ">
                                      {sub_Item.description}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <TakeoutOnly />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Restauranttime
        openRestTimeModal={openRestTimeModal}
        setOpenRestTime={setOpenRestTime}
      />
      <AddCartItem
        show={show}
        setShow={setShow}
        selectedItem={selectedItem}
        outletId={outletId}
      />
    </>
  );
};
export default RestaurantMenu;
