import React from 'react';
import Nearbyrest from './Nearbyrest';

import Topcuisines from './Topcuisines';
import Foodtag from '../common/Foodtag';


const Home = () => {
    return (
        <div>
            <app-header></app-header>
            <main>
                <div className="main-banner-bg">
                    <div className="cus_container">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="col-left">
                                <h1 className="head1">
                                    Make Healthy
                                    Life with <span>Takkout</span>
                                </h1>
                                <p className="txt mt-3 mb-4">Here you will find all the best quality & pure food. Order now
                                    to satisfy your hunger pages.</p>
                                <form>
                                    <div className="inputGroup inputmain-banner">
                                        {/* <!-- <span className="input-filltxt-show">Burger</span> --> */}
                                        <input type="text" className="input_control input-inside-img bg-white" />
                                    </div>
                                </form>
                                <button className="cusbtn btnsize17 mt-4">Get Started</button>
                            </div>
                            <div className="col-right">
                                <img src="./assets/img/main_bannerImg.png" alt="img banner" className="banner-img-RS" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <section className="top_cuisines">
                <div className="cus_container">
                    <div className="d-flex">
                        {/* filter box  */}
                        <Foodtag />

                        {/* top cuisines section  */}
                        <Topcuisines />
                        {/* top cuisines section end */}

                    </div>
                </div>

            </section>
            <Nearbyrest />

        </div>
    );
};

export default Home;