import React from "react";
import { Modal } from "react-bootstrap";
import { loginPost } from "../../redux/actions/authActions";
import Auth from "../../auth/Auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function Logout(props) {
  const { openLogoutModal, setOpenLogoutModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Modal
      show={openLogoutModal}
      onHide={() => setOpenLogoutModal(false)}
      centered
      className="logoutmodal center-close-icon"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="bodyModal">
        <h4 className="head4 text-center mb-0 bor_lineR pb-0">Log Out</h4>
        <div className="img-logut my-5">
          <img src="./assets/img/logoutmodal_img.png" alt="" />
        </div>
        <p className="txt text-center">Are you sure you want to log out?</p>
        <div className="footerM text-center mt-4">
          <button
            className="cusbtn me-3 minonehunt"
            onClick={() => {
              dispatch(loginPost(false));
              Auth.logout();
              navigate("/");
            }}
          >
            Yes
          </button>
          <button
            className="hoverbtn minonehunt"
            onClick={() => setOpenLogoutModal(false)}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Logout;
