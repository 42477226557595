import {
  ADD_CART_ITEM,
  GET_CART_ITEMS,
  UPDATE_CART_OUTLET_ID,
  UPDATE_CART_LOADER
} from "../actionTypes/cartTypes";

const initalState = {
  cartItems: [],
  isCartItemUpdated: {},
  cartOutletId: null,
  cartLoader: false,
};

export function cartReducer(state = initalState, action) {
  switch (action.type) {
    case ADD_CART_ITEM:
      return {
        ...state,
        isCartItemUpdated: {},
        cartItems: [...state.cartItems, action.payload],
      };
    case GET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case UPDATE_CART_OUTLET_ID:
      return {
        ...state,
        cartOutletId: action.payload,
      };
    case UPDATE_CART_LOADER:
      return {
        ...state,
        cartLoader: action.payload
      }
    default:
      return state;
  }
}
