import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCuisines } from "../../redux/actions/authActions";
import { http } from '../../helpers/http';


const Topcuisines = () => {
    const cuisinesPerRow = 10;

    const [next, setNext] = useState(cuisinesPerRow);
    console.log(next, "next")

    const dispatch = useDispatch();
    const state = useSelector((state) => state.auth.getCuisinesData?.cuisines)
    console.log(state?.length, "stae")

    // const [size, setSize] = useState(10);

    //GET CUISINES LIST//
    const getCuisinesData = () => {
        http(
            {
                method: "GET",
                url: `listCuisines`,
                isSecure: true,
            },
            (res) => {
                dispatch(fetchCuisines(res))

            },
            (res) => {

            }
        );
    }

    console.log('cuisinesPerRow', cuisinesPerRow, 'next', next)
    const handleMoreCuisines = () => {
        // if(next<state?.length){
        //     setNext(next + cuisinesPerRow);
        // }
        setNext(next + cuisinesPerRow);
    };

    const handleLessCuisines = () => {
        setNext(next - cuisinesPerRow)
    }
    console.log(next, "pre")

    useEffect(() => {
        getCuisinesData();
    }, []);
    return (
        <>
            <div className="country_name_section">
                <div>
                    <h4 className="head4 text-center">What We Have</h4>
                    <h3 className="head3 text-center">Top Cuisines</h3>
                    <div className="cuisines_cout">
                        <div className="country_group">
                            {
                                state && state?.slice(0, next)?.map((item, index) => {
                                    return (
                                        <div key={'cui' + index} className="country-name">{item.CuisinesName}</div>
                                    )
                                })
                            }

                        </div>

                        {next < state?.length ?
                            <div className="text-center middle-line-RL mb-4 mt-lg-5 mt-3 ">
                                <button className="cusbtn btnsize17" onClick={handleMoreCuisines}>Load More</button>
                            </div> :

                            null
                        }
                        {/* <div className="text-center middle-line-RL mb-4 mt-lg-5 mt-3 ">
                                <button className="cusbtn btnsize17" onClick={handleLessCuisines}>Load Less</button>
                            </div> */}
                    </div>

                </div>
            </div>

        </>
    );
}

export default Topcuisines;