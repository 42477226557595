import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Auth from "../../auth/Auth";
import { http } from "../../helpers/http";
import { useDispatch, useSelector } from "react-redux";
import uuid from "uuid";
import { addCartItem, updateCartLoader } from "../../redux/actions/cartActions";
import { toast } from "react-toastify";
import { updateOnboardingState } from "../../redux/actions/authActions";
import ReplaceCartItems from "./ReplaceCartItems";

function AddCartItem(props) {
  const { show, setShow, selectedItem, outletId } = props;
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartOutletId = useSelector((state) => state.cart.cartOutletId);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [customisation, setCustomisation] = useState([]);
  const [calculativePrice, setCalculativePrice] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const ifNewOutlet = cartOutletId ? cartOutletId !== outletId : false;

  useEffect(() => {
    setCalculativePrice(selectedItem.price);
  }, [selectedItem.price]);

  useEffect(() => {
    //reset the values upon modal close
    if (!show) {
      setCalculativePrice(selectedItem.price);
      setQuantity(1);
      setCustomisation([]);
    }
  }, [show]);

  useEffect(() => {
    setTotalPrice();
  }, [quantity]);

  useEffect(() => {
    setTotalPrice();
  }, [customisation]);

  const setTotalPrice = () => {
    if (!!selectedItem.price) {
      let addOnPrice = 0;
      customisation.forEach((item) => (addOnPrice = addOnPrice + item.Price));
      const result = (+selectedItem.price + addOnPrice) * quantity;
      setCalculativePrice((Math.round(result * 100) / 100).toFixed(2));
    }
  };

  const addOnHandler = (event, data, index) => {
    if (event.target.checked) {
      setCustomisation((previousState) => {
        return [...previousState, data];
      });
    } else {
      setCustomisation((previousState) => {
        const newState = [...previousState];
        newState.find((item, index) => {
          if (item.elementId === data.elementId) {
            newState.splice(index, 1);
            return true;
          }
          return false;
        });
        return newState;
      });
    }
  };

  const addItemToCart = () => {
    const formattedCustomisation = customisation.map((item) => ({
      dishCustomisationId: item.elementId,
    }));

    const newCartItem = {
      quantity,
      customisation: formattedCustomisation,
      dishId: selectedItem.dishId,
      uuId: uuid.v4().toUpperCase(),
    };

    const apiFormData = new FormData();
    apiFormData.append("outletId", outletId);
    apiFormData.append("udId", Auth.userInfo().udId);
    apiFormData.append(
      "dishes",
      JSON.stringify(
        ifNewOutlet ? [newCartItem] : [...cartItems, newCartItem]
      )
    );

    dispatch(updateCartLoader(true));
    http(
      {
        method: "POST",
        url: `addToCart`,
        isSecure: true,
        contentType: "Multipart/form-data",
        body: apiFormData,
      },
      (res) => {
        if (res.error === "false") {
          toast.success("Cart item is added successfully!");
          dispatch(addCartItem(newCartItem));
          setShow(false);
        }
      },
      (res) => { }
    );
  };

  const submitHandler = () => {
    if (!Auth.isUserAuthenticated()) {
      // alert("Please login or sign up to proceed");
      setShow(false);
      dispatch(updateOnboardingState("login"));
      return;
    }

    if (ifNewOutlet) {
      setShow(false);
      setOpenModal(true);
    } else {
      addItemToCart();
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="bodyModal">
          <h6 className="head6 mb-1 blackC">
            {selectedItem.dishName}
            <img
              src="./assets/img/veg_icon.svg"
              alt="veg img"
              className="ms-2"
            />
          </h6>
          <h6 className="head6 mb-1 blackC">{selectedItem.displayPrice}</h6>
          <p className="txt">{selectedItem.description}</p>

          {!!selectedItem.dishCustomisation?.length && (
            <>
              <div className="line-content-middle my-3"></div>
              <h6 className="head6 mb-2 blackC">Add On</h6>
              <p className="txt blackC">Select Up to 2 Options</p>
            </>
          )}
          {(selectedItem.dishCustomisation || []).map((item) => (
            <Fragment key={item.customizableId}>
              {item.customizableDishItems.map((customizableDishItem, index) => (
                <div
                  key={`customizableDishItem-${customizableDishItem.elementId}`}
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <p className="txt blackC mb-0">
                    {customizableDishItem.elementName}
                  </p>
                  <p className="txt blackC mb-0 d-flex">
                    {customizableDishItem.displayPrice}
                    <span className="radio-group ms-2">
                      <label className="checkbox-btn">
                        <input
                          type="checkbox"
                          onChange={(event) =>
                            addOnHandler(event, customizableDishItem, index)
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </span>
                  </p>
                </div>
              ))}
            </Fragment>
          ))}
          {/* <div className="d-flex align-items-center justify-content-between mb-2">
          <p className="txt blackC mb-0">Cheese Dip</p>
          <p className="txt blackC mb-0 d-flex">
            $5.00{" "}
            <div className="radio-group ms-2">
              <label className="checkbox-btn">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <p className="txt blackC mb-0">Spicy Dip</p>
          <p className="txt blackC mb-0 d-flex">
            $5.00{" "}
            <div className="radio-group ms-2">
              <label className="checkbox-btn">
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>
          </p>
        </div> */}
          <div className="line-content-middle my-3"></div>
          <h6 className="head6 mb-2 blackC">Special Instructions</h6>
          <div className="modaltextarea">
            <textarea className="textarea"></textarea>
          </div>
          <div className="footerM text-center mt-4 d-flex align-items-center">
            <div className="add_num me-3">
              <button
                className="btnAdd_pr"
                onClick={() => {
                  if (quantity !== 1) {
                    setQuantity((ps) => {
                      return ps - 1;
                    });
                  }
                }}
              >
                -
              </button>
              <div>{quantity}</div>
              <button
                className="btnAdd_pr"
                onClick={() =>
                  // setFormData((ps) => {
                  //   ps.dishes[0].quantity = ps.dishes[0].quantity + 1;
                  //   const newData = { ...ps };
                  //   return newData;
                  // })
                  setQuantity((ps) => {
                    return ps + 1;
                  })
                }
              >
                +
              </button>
            </div>
            <button className="cusbtn w-100" onClick={submitHandler}>
              Add Item - <span>${calculativePrice}</span>
            </button>
          </div>
        </div>
      </Modal>
      <ReplaceCartItems
        show={openModal}
        setShow={setOpenModal}
        addItemToCart={addItemToCart}
      />
    </>
  );
}

export default AddCartItem;
