import Auth from "../auth/Auth";

const getHeaders = ({
    method,
    contentType = "application/json",
    body,
    isSecure,
}) => ({
    method: method,
    headers: {
        Accept: "application/json",
        ...(contentType === "application/json" && { "Content-Type": contentType }),
        ...(isSecure && { Authorization: "Bearer " + Auth.token() }),
    },
    ...(body && {
        body: (contentType === "application/json" ? JSON.stringify(body) : body),
    }),
});

export const http = async (
    { method, url, body, isSecure, contentType },
    onSuccess = () => "",
    onError = () => ""
) => {
    const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${url}`,
        getHeaders({ method, contentType, body, isSecure })
    );

    // REQ SUCCESS WITH RESPONSE
    if (response.status === 200) {
        const res = await response.json();
        onSuccess(res);
    }
    // REQ SUCCESS WITHOUT RESPONSE
    else if (response.status > 200 && response.status <= 299) {
        onSuccess(response);
    }
    // UNAUTHENTICATED ERROR
    else if (response.status === 403) {
        const res = await response.json();
        Auth.logout();
        onError({ status: 403, message: res.error_description });
    }
    // VALIDATION ERRORS
    else if (response.status === 400 || response.status === 401) {
        const res = await response.json();
        Auth.logout();
        onError({ status: response.status || 400, message: res.message });
    }
};
