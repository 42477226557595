import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { http } from "../../helpers/http";
import { toast } from "react-toastify";

function RateUs(props) {
  const { rateUsModal, setRateUsModal, selectedItem } = props;
  const [orderRating, setOrderRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  const onHideHandler = () => {
    setRateUsModal(false);
    setOrderRating(0);
    setFeedback("");
  };

  const submitHandler = () => {
    const body = {
      orderId: selectedItem.orderId,
      outletId: selectedItem.orderDetails.outletId,
      orderRating,
      feedback,
      staffId: selectedItem.deliveryStaffId,
    };

    http(
      {
        method: "POST",
        url: `rating`,
        isSecure: true,
        body,
      },
      (res) => {
        if (res.error === "false") {
          toast.success(res.errorMessage);
          setRateUsModal(false);
        } else {
          toast.error(res.errorMessage);
        }
      },
      (res) => {}
    );
  };

  return (
    <Modal
      show={rateUsModal}
      onHide={onHideHandler}
      centered
      className="ratingmodal center-close-icon"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="bodyModal">
        <h4 className="head4 text-center mb-0 bor_lineR pb-0">Rate Us</h4>
        <h6 className="head6 text-center mt-5">Veggie Noodle</h6>
        <p className="txt text-center light_b">
          How was the food? Please rate the restaurants.
        </p>
        <div className="rating_modal_box my-3">
          <StarRatings
            rating={orderRating}
            starDimension="40px"
            starSpacing="15px"
            starRatedColor="#FEBD30"
            starHoverColor="#FEBD30"
            changeRating={(newRating) => setOrderRating(newRating)}
          />
        </div>
        <h6 className="head6 text-center mb-4">Any other comments?</h6>
        <div className="modaltextarea">
          <textarea
            className="textarea"
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
          >
            {feedback}
          </textarea>
        </div>
        <div className="footerM text-center mt-4">
          <button className="cusbtn w-100" onClick={submitHandler}>
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default RateUs;
