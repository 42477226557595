import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import Auth from "../../auth/Auth";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-js-loader";
import {
  getCartItems,
  updateCartLoader,
  updateCartOutletId,
} from "../../redux/actions/cartActions";
import { toast } from "react-toastify";

const TakeoutOnly = () => {
  const { isCartItemUpdated, cartOutletId, cartLoader } = useSelector(
    (state) => state.cart
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState({});
  const { pathname } = useLocation();
  const status = pathname.includes("restaurantmenu");

  useEffect(() => {
    getCartData();
  }, [isCartItemUpdated]);

  const getCartData = () => {
    http(
      {
        method: "POST",
        url: `viewCart`,
        isSecure: true,
        body: { udId: Auth.userInfo()?.udId, order_type: "Take Out" },
      },
      (res) => {
        if (res.error === "false") {
          setCartData(res);
          dispatch(updateCartOutletId(res.dishes.outletDetails.outletId));
          const oldCartItems = [];
          res?.dishes?.dishes.forEach((dishItem) => {
            const item = {
              dishId: dishItem.dishId,
              quantity: dishItem.dishQuantity,
              uuId: dishItem.uuId,
              customisation: [],
            };
            dishItem.dishCustomisation.forEach((dishCustomisationItem) => {
              dishCustomisationItem.customizableDishItems.forEach(
                (customizableDish) => {
                  item.customisation.push({
                    dishCustomisationId: customizableDish.elementId,
                  });
                }
              );
            });
            oldCartItems.push(item);
          });
          dispatch(getCartItems(oldCartItems));
          dispatch(updateCartLoader(false));
        }
      },
      (res) => { }
    );
  };

  const updateQuantity = (key, value) => {
    let newQuantity = value.dishQuantity;
    if (key === "decrease" && newQuantity === 1) return;
    const apiData = {
      udId: Auth.userInfo()?.udId,
      outletId: cartOutletId,
      quantity: key === "increase" ? ++newQuantity : --newQuantity,
      cartId: value.cartId,
    };
    dispatch(updateCartLoader(true));
    http(
      {
        method: "POST",
        url: `updateCart`,
        isSecure: true,
        body: apiData,
      },
      (res) => {
        if (res.error === "false") {
          getCartData();
          toast.success("Quantity is updated successfully!");
        }
      }
    );
  };

  const checkoutHandler = (value) => {
    status ? navigate("/checkout") : navigate(-1);
  };

  return (
    <>
      {cartData.dishes ? (
        <div className="right_box_hug">
          {cartLoader && (
            <div className="loader-cus">
              <Loader
                type="spinner-circle"
                bgColor={"#fff"}
                color={"#fff"}
                size={100}
              />
            </div>
          )}
          <div className="white_box">
            <h4 className="head4 bor_lineR pb-0">Takkout Only</h4>
            <p className="txt txt_bor_b my-3 light_b">
              Ready in {cartData?.dishes.outletDetails.preparationTime} min
            </p>
            {cartData?.dishes?.dishes.map((dishItem) => (
              <div key={`dish-id-${dishItem.dishId}`}>
                <div className="line-content-middle my-3"></div>
                <h5 className="head5">{dishItem.dishName}</h5>
                {dishItem.dishCustomisation.map((dishCustomisationItem) => (
                  <Fragment
                    key={`customizableId-${dishCustomisationItem.customizableId}`}
                  >
                    {dishCustomisationItem.customizableDishItems.map(
                      (customizableDish) => (
                        <p
                          key={`elementId-${customizableDish.elementId}`}
                          className="txt mb-1 light_b"
                        >
                          {customizableDish.elementName}
                        </p>
                      )
                    )}
                  </Fragment>
                ))}
                {/* <p className="txt">
                <sup>*</sup>
                <span className="light_b">Less Spicy Please</span>
              </p> */}
                {/* <div className="line-content-middle my-3"></div> */}
                <div className="d-flex align-items-center justify-content-between">
                  <div className="txt light_b d-flex">
                    {status && (
                      <span
                        className="me-2 pointer"
                        name="minus"
                        value="minus"
                        onClick={() => updateQuantity("decrease", dishItem)}
                      >
                        <img
                          src="./assets/img/minus.svg"
                          alt="filter"
                          className="filterd-flex align-items-center"
                        />
                      </span>
                    )}
                    <span className="me-2">
                      {"Qty: "}
                      {dishItem.dishQuantity}
                    </span>
                    {status && (
                      <span
                        className="me-2 pointer"
                        name="plus"
                        onClick={() => updateQuantity("increase", dishItem)}
                      >
                        <img
                          src="./assets/img/plus.svg"
                          alt="filter"
                          className="filter d-flex align-items-center"
                        />
                      </span>
                    )}
                  </div>
                  <div className="head6">{dishItem.displayDishTotal}</div>
                </div>
              </div>
            ))}
            {/* <div className="line-content-middle my-3"></div>

          <h5 className="head5">Veggie Noodle</h5>
          <p className="txt light_b">Cheese Dip</p>

          <div className="d-flex align-items-center justify-content-between">
            <div className="txt light_b">
              Qty: <span>1</span>
            </div>
            <div className="head6">$15.25</div>
          </div> */}
            <div className="line-content-middle my-3"></div>
            <div className="totle_amount">
              {cartData.dishes?.billTotals.map((billCategory) => (
                <div
                  key={`${billCategory.displayKey}-${billCategory.displayValue}`}
                  className="d-flex align-items-center justify-content-end mb-2"
                >
                  <div className="txt light_b" style={{ fontSize: "12px" }}>
                    {billCategory.displayKey}:
                  </div>
                  <div className="head6">{billCategory.displayValue}</div>
                </div>
              ))}
              <div className="btn_container mt-5">
                <button className="cusbtn w-100" onClick={checkoutHandler}>
                  {status ? "Checkout" : "Modify Order"}
                  {status && (
                    <span className="px-2 ms-2 rounded-circle text-dark bg-white text-body">
                      {cartData?.dishes?.dishes.length}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-right right_box_hug">
          <div className="white_box">
            <h4 className="head4 bor_lineR pb-0">Takkout Only</h4>
            <div className="hungry-img my-5 text-center">
              <img src="./assets/img/hungry.png" alt="" />
            </div>
            <h6 className="head6 text-center">Hungry?</h6>
            <p className="txt">Add menu items to your order.</p>
          </div>
        </div>
      )}
    </>
  );
};
export default TakeoutOnly;
