import React, { useState, useEffect } from "react";
import TakeoutOnly from "../common/TakeoutOnly";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import Form from "react-bootstrap/Form";
import { patterns, useForm } from "../../helpers/useForm";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import Auth from "../../auth/Auth";
import { useDispatch, useSelector } from "react-redux";
import {

  dispatchLoadingStart,
  dispatchLoadingStop,

} from "../../redux/actions/authActions";

//styling of inputobx
const Options = {
  style: {
    base: {
      fontSize: "16px",
      lineHeight: "28px",
      ":-webkit-autofill": { color: "#252C53" },
      "::placeholder": {
        fontSize: "16px",
        fontFamily: "Montserrat, sans-serif",
        color: "#617182",
        textTransform: "uppercase",
      },
    },
    invalid: {
      color: "#ff2955",
    },
  },
};

const CheckOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [showCardModal, setShowCModalard] = useState(false);
  const [getCheckProfile, setGetCheckProfile] = useState({});
  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      userName: {
        pattern: {
          value: patterns.userName,
          message: "Please enter valid name address.",
        },
        required: true,
      },
      email: {
        pattern: {
          value: patterns.email,
          message: "Invalid email address.",
        },
        required: true,
      },
    },
  });

  const getCheckoutProfile = () => {
    dispatch(dispatchLoadingStart());
    http(
      {
        method: "GET",
        url: `getProfile`,
        isSecure: true,
      },
      (res) => {
        setGetCheckProfile(res.usersDetails);
        dispatch(dispatchLoadingStop());
      }
    );
  };

  useEffect(() => {
    if (!Auth.isUserAuthenticated()) {
      navigate("/");
      return;
    }
    getCheckoutProfile();
  }, []);

  const upDateProfile = (event) => {
    event.preventDefault();
    dispatch(dispatchLoadingStart());
    http(
      {
        method: "POST",
        url: `updateProfile`,
        isSecure: true,
        body: {
          userName: values.userName,
          email: values.email,
          countryCode: JSON.parse(localStorage.getItem("__takk_out"))
            ?.countryCode,
          mobileNumber: values.mobileNumber,
        },
      },
      (res) => {
        console.log(res, "updateprofile");
        dispatch(dispatchLoadingStop())
      }
    );
  };

  const upDateCard = async (event) => {
    console.log("token", stripe, "elements", elements);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(cardNumberElement);

    console.log("token", token);
  };

  return (
    <>
      <main>
        <div className="restaurant_inner_bg needmin-hmain">
          <div className="cus_container">
            <h1 className="head1 text-center">Check Your Box</h1>
          </div>
        </div>
      </main>
      <section className="checkout_section">
        <div className="cus_container">
          <button
            className="arrow_back d-inline bg-white"
            onClick={() => navigate(-1)}
          >
            <img src="./assets/img/arrowBack.svg" alt="arrow back" />
            Back
          </button>
          <div className="d-flex mt-4">
            <Form onSubmit={upDateProfile}>
              <div className="col-left">
                <h4 className="head4 mt-4 bLine-five">Contact Info</h4>
                <div className="white_box mt-4 p-4">
                  <div className="row">
                    <div className="col-6">
                      <div className="input-grbox">
                        { }
                        <label className="blackC">
                          Name<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          name="userName"
                          {...bindField("userName")}
                          className="input_control"
                          placeholder={getCheckProfile?.userName}
                        />
                      </div>
                    </div>
                    {errors.userName && (
                      <span className="text-danger err small">
                        {errors.userName}
                      </span>
                    )}
                    <div className="col-6">
                      <div className="input-grbox">
                        <label className="blackC">
                          Email<sup>*</sup>
                        </label>
                        <input
                          type="email"
                          {...bindField("email")}
                          name="email"
                          className="input_control"
                          placeholder={getCheckProfile?.email}
                        />
                      </div>
                    </div>
                    {errors?.email && (
                      <span className="text-danger err small">
                        {errors?.email}
                      </span>
                    )}
                  </div>
                  <div className="row mt-4">
                    <div className="col-6 ">
                      <div className="input-grbox">
                        <label className="blackC">
                          Phone Number<sup>*</sup>
                        </label>
                        <input
                          type="number"
                          className="input_control"
                          name="mobileNumber"
                          {...bindField("mobileNumber")}
                          placeholder={getCheckProfile?.mobileNumber}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                      <div className="input-grbox">
                        <label className="blackC">
                          Instructions (Optional)
                        </label>
                        <input type="text" className="input_control" />
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="head4 mt-4 bLine-five">Payment Info</h4>
                <div className="white_box mt-4 p-4">
                  <p className="txt">Choose your preferred payment method</p>
                  <div className="payment_card">
                    <button className="cardbtn active_card">
                      <img src="./assets/img/crcard.svg" alt="Credit Card" />
                      <span className="cr-name ms-2">Credit Card</span>
                    </button>
                    <button className="cardbtn ms-3">
                      <img src="./assets/img/paypal.svg" alt="Credit Card" />
                      <span className="cr-name ms-2">Credit Card</span>
                    </button>
                  </div>
                  <div className="payment_card_view p-3 mt-3">
                    <div className="d-flex align-items-center">
                      <img
                        src="./assets/img/card_img_view.svg"
                        alt="card icon"
                        className="me-2"
                      />
                      <img
                        src="./assets/img/visa_icon.svg"
                        alt="visa icon"
                        className="me-2"
                      />
                      <div className="txt me-2">
                        **** **** **** <span>3704</span>
                      </div>
                      <img
                        src="./assets/img/edit_card.svg"
                        alt="edit icon"
                        className="me-2"
                      />

                      <div className="radio-group ">
                        <label className="checkbox-btn">
                          <input type="radio" name="card" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="line-content-middle my-3"></div>
                    <div className="d-flex align-items-center">
                      <img
                        src="./assets/img/card_img_view.svg"
                        alt="card icon"
                        className="me-2"
                      />
                      <img
                        src="./assets/img/visa_icon.svg"
                        alt="visa icon"
                        className="me-2"
                      />
                      <div className="txt me-2">
                        **** **** **** <span>3704</span>
                      </div>
                      <img
                        src="./assets/img/edit_card.svg"
                        alt="edit icon"
                        className="me-2"
                      />

                      <div className="radio-group ">
                        <label className="checkbox-btn">
                          <input type="radio" name="card" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button
                      className="newbtn_addcard mt-4"
                      onClick={() => setShowCModalard(true)}
                    >
                      + Add New Card
                    </button>
                  </div>
                </div>
                <h4 className="head4 mt-4 bLine-five">Tip</h4>
                <div className="white_box mt-4 p-4">
                  <p className="txt">Add a tip ?</p>
                  <div className="payment_card">
                    <button className="cardbtn active_card">
                      <img src="./assets/img/dollar.svg" alt="Credit Card" />
                      <span className="cr-name ms-2">10%</span>
                    </button>
                    <button className="cardbtn ms-3">
                      <img src="./assets/img/dollar.svg" alt="Credit Card" />
                      <span className="cr-name ms-2">15%</span>
                    </button>
                    <button className="cardbtn ms-3">
                      <img src="./assets/img/coins.svg" alt="Credit Card" />
                      <span className="cr-name ms-2">20%</span>
                    </button>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6 col-8">
                      <div className="input-grbox">
                        <input
                          type="number"
                          className="input_control"
                          placeholder="$ Custom Amount"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-4">
                      <button className="cusbtn minonehunt" disabled>
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6 col-8">
                      <div className="input-grbox">
                        <label className="blackC">Promo Code</label>
                        <input
                          type="text"
                          className="input_control"
                          placeholder="Your Code"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-4">
                      <button className="cusbtn minonehunt mt-4" disabled>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <button type="submit" className="cusbtn btnsize17 mt-4">
                  Place Order
                </button>
              </div>
            </Form>
            <div className="col-right">
              <TakeoutOnly />
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showCardModal}
        onHide={() => setShowCModalard(false)}
        centered
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>
        <form onSubmit={upDateCard}>
          <div className="bodyModal">
            <h4 className="head4 text-center mb-0 bor_lineR pb-0">
              Add Credit Card
            </h4>
            <div className="mt-5">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="input-grbox">
                    <label className="blackC">
                      Card Number<sup>*</sup>
                    </label>
                    <div className="fillinput">
                      <CardNumberElement options={Options} />
                    </div>
                    {/* <input
                      type="number"
                      className="input_control"
                      placeholder="Enter Your Card Number"
                    /> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-grbox">
                    <label className="blackC">
                      MM/YY<sup>*</sup>
                    </label>
                    <div className="fillinput">
                      <CardExpiryElement options={Options} />
                    </div>
                    {/* <input
                      type="text"
                      className="input_control"
                      placeholder="Enter MM/YY"
                    /> */}
                  </div>
                </div>
                <div className="col-6">
                  <div className="input-grbox">
                    <label className="blackC">
                      CVV<sup>*</sup>
                    </label>
                    <div className="fillinput">
                      <CardCvcElement options={Options} />
                    </div>
                    {/* <input
                      type="text"
                      className="input_control"
                      placeholder="CVV"
                    /> */}
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="input-grbox">
                    <label className="blackC">
                      Billing Zip<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="input_control"
                      placeholder="Enter Billing Zip"
                    />
                  </div>
                </div>
              </div>

              <div className="btnCont-mt">
                <button type="submit" className="cusbtn w-100 text-center">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default CheckOut;
