import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers";
// import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import logger from "redux-logger";

const middleware = [logger];
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    // offline(offlineConfig),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
