import React from 'react'

function ContactUs() {
    return (
        <>
            <main>
                <div className="restaurant_inner_bg needmin-hmain">
                    <div className="cus_container">
                        <h1 className="head1 text-center">Contact Us</h1>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ContactUs