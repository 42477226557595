class Auth {
    authenticated = false;
    key = "__takk_out";

    login(data) {
        const user = JSON.stringify(data);

        localStorage.setItem(this.key, user);

        if (user) {
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
    }

    userInfo() {
        let user = JSON.parse(localStorage.getItem(this.key));
        return user;
    }

    token() {
        const user = JSON.parse(localStorage.getItem(this.key));
        return user && user?.token;
    }

    logout() {
        localStorage.clear();
        this.authenticated = false;
    }

    isUserAuthenticated() {
        return localStorage.getItem(this.key) ? true : false;
    }
}
export default new Auth();
