import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux';
const Foodtag = () => {
    const dispatch = useDispatch();


    return (
        <>
            <div className="item_filter_box">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <img src="./assets/img/white_filter.svg" alt="filter"
                                className="filter me-2 d-flex align-items-center iconDre_size" />
                            <span className="accor-titile">Filters</span>

                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="filter-item d-flex mb-2">
                                <div className="radio-group ">
                                    <label className="checkbox-btn">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>

                                    </label>
                                </div>
                                Popularity
                            </div>
                            <div className="filter-item d-flex">
                                <div className="radio-group">
                                    <label className="checkbox-btn">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>

                                    </label>
                                </div>
                                Rating: High to Low
                            </div>
                            <div className="line-content-middle my-3"></div>
                            <div className="fillter_input_gr">
                                <label>Zip Code</label>
                                <input type="text" className="input_control light_pink" />
                            </div>
                            <div className="line-content-middle my-3"></div>
                            <div className="fillter_input_gr">
                                <label>Restaurants</label>
                                <div className="toggle_group-fil mt-3">
                                    <button className="toggle-btn-f">All</button>
                                    <button className="toggle-btn-f active">Opened</button>
                                </div>
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="no-need_arrow">
                            <img src="./assets/img/star_Accor.svg" alt="filter"
                                className="filter me-2 d-flex align-items-center" />
                            <span className="accor-titile">Rating 4.0+</span>
                        </Accordion.Header>

                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <img src="./assets/img/foodtag.svg" alt="filter"
                                className="filter me-2 d-flex align-items-center" />
                            <span className="accor-titile">Food Tags</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="fillter_input_gr extra-input-filled">
                                <input type="text" className="input_control input-inside-img bg-white"
                                    placeholder="Search Here.." />
                            </div>


                            <div className="filter_search-items mt-lg-4 mt-3 px-lg-3 max-heig-serch">
                                <div className="filter-item d-flex mb-2">
                                    <div className="radio-group">
                                        <label className="checkbox-btn">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>

                                        </label>
                                    </div>
                                    Burger
                                </div>
                                <div className="filter-item d-flex mb-2">
                                    <div className="radio-group">
                                        <label className="checkbox-btn">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>

                                        </label>
                                    </div>
                                    Pasta
                                </div>
                                <div className="filter-item d-flex mb-2">
                                    <div className="radio-group">
                                        <label className="checkbox-btn">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>

                                        </label>
                                    </div>
                                    Noodle
                                </div>
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <img src="./assets/img/filterMore.svg" alt="filter"
                                className="filter me-2 d-flex align-items-center" />
                            <span className="accor-titile">More</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="filter-item d-flex mb-2">
                                <div className="radio-group ">
                                    <label className="checkbox-btn">
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>

                                    </label>
                                </div>
                                Previously Ordered
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>

                </Accordion>

                <div className="line-content-middle my-3"></div>
                <div className=" px-3 ">
                    <div className="filter-title ">
                        By Price
                    </div>
                    <div className="rang-box  py-2">
                        <img src="./assets/img/multiple_range.png" alt="rang" className="w-100" />
                    </div>
                    <div className="filter-title Price-ft">
                        Price: <span>$0</span> - <span>$100</span>
                    </div>
                </div>
                <div className="line-content-middle my-3"></div>
                <div className=" px-3 ">
                    <div className="filter-title ">
                        By Distance
                    </div>
                    <div className="rang-box  my-2">
                        <img src="./assets/img/singleRange.png" alt="rang" className="w-100" />
                    </div>
                    <div className="filter-title rang-dis">
                        0km
                    </div>
                </div>
                <div className="filterbtn d-flex align-items-center mt-5 px-lg-3">
                    <button className="cusbtn me-lg-3 mb-3 mb-lg-0 w-100">Apply</button>
                    <button className="hoverbtn w-100">Clear All</button>

                </div>
                <img src="./assets/img/filter_f-img.png" alt="food img" className="footerimg-filter" />
            </div>

        </>
    );
};
export default Foodtag;
