import React from "react";
import { Button, Modal } from "react-bootstrap";

function ReplaceCartItems(props) {
  const { show, setShow, addItemToCart } = props;

  const yesHandler = () => {
    addItemToCart();
    setShow(false);
  };

  const noHandler = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Replace cart item?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your cart contains dishes from Other Restaurant. Do you want to discard
        the selection and add dishes from this Restaurant?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={yesHandler}>
          Yes
        </Button>
        <Button variant="secondary" onClick={noHandler}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReplaceCartItems;
