import {
  ADD_CART_ITEM,
  GET_CART_ITEMS,
  UPDATE_CART_LOADER,
  UPDATE_CART_OUTLET_ID,
} from "../actionTypes/cartTypes";

export function addCartItem(payload) {
  return { type: ADD_CART_ITEM, payload };
}

export function getCartItems(payload) {
  return { type: GET_CART_ITEMS, payload };
}

export function updateCartOutletId(payload) {
  return { type: UPDATE_CART_OUTLET_ID, payload };
}

export function updateCartLoader(payload) {
  return { type: UPDATE_CART_LOADER, payload };
}
