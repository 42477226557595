import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";

import { http } from "../../helpers/http";
import uuid from "uuid";
import { toast } from "react-toastify";
import { patterns, useForm } from "../../helpers/useForm";
import Auth from "../../auth/Auth";
import {
  loginPost,
  dispatchLoadingStart,
  dispatchLoadingStop,
  updateOnboardingState,
} from "../../redux/actions/authActions";
import { Link, useNavigate } from "react-router-dom";

const Onboarding = () => {
  // const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const onboardingState = useSelector((state) => state.auth.onboardingState);
  const navigate = useNavigate();
  const loggedUser = Auth.userInfo();
  const [isExisting, setIsExisting] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);
  const [checkUser1, setcheckUser1] = useState("");

  const [showPassword, setshowPassword] = useState({
    password: false,
  });
  const [otpValue, setOtpValue] = useState();
  console.log(otpValue, "otpValue")


  const { values, errors, bindField, isValid, setInitialValues } = useForm({
    validations: {
      ...((onboardingState === "login" ||
        onboardingState === "fpModal" ||
        onboardingState === "signUp") && {
        mobileNumber: {
          required: true,
          maxLength: {
            value: 10,
            message: "Mobile number must be 10 digits",
          },
          minLength: {
            value: 10,
            message: "Mobile number must be 10 digits",
          },
          pattern: {
            value: patterns.onlyNumber,
            message: "Please enter vaild phone number",
          },
        },
      }),

      ...((onboardingState === "signUp" || isExisting) &&
        onboardingState !== "fpModal" && {
        password: {
          required: true,
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters.",
          },
        },
      }),
      ...(onboardingState === "signUp" && {
        otpNumber: {
          required: true,
        },
        countryCode: {
          required: true,
        },
        udId: {
          required: true,
        },
        userName: {
          required: true,
        },
        email: {
          pattern: {
            value: patterns.email,
            message: "Invalid email address.",
          },
          required: true,
        },
        checkbox: {
          required: true
        }
      }),
    },
  });

  const formSubmitHandler = (event, formSubmitType) => {
    event.preventDefault();
    dispatch(dispatchLoadingStart());
    let apiUrl = "";
    let bodyData = values;

    switch (formSubmitType) {
      case "number_Check":
        apiUrl = "CheckAvailability";
        if (isExisting) {
          apiUrl = "userPasswordLogin";
          bodyData.udId = uuid.v4().toUpperCase();
        }
        break;
      case "login":
        apiUrl = "userPasswordLogin";
        bodyData.udId = uuid.v4().toUpperCase();
        break;

      case "signUp":
        apiUrl = "userWithPasswordSignup";
        bodyData.udId = uuid.v4().toUpperCase();
        break;

      case "enterOtp":
        apiUrl = "otpVerification";
        bodyData.otpNumber = otpValue;
        bodyData.udId = uuid.v4().toUpperCase();
        break;
      case "resetPassword":
        apiUrl = "changePassword";
        if (bodyData?.udId) {
          delete bodyData.udId;
        }
        break;

      case "fpModal":
        apiUrl = "forgotPassword";
        break;

      case "reSendOTP":
        apiUrl = "resendOtp";

      default:
        break;
    }
    // setShowLoader(true)

    http(
      {
        method: "POST",
        url: apiUrl,
        isSecure: true,
        body: bodyData,
      },
      (response) => {
        setcheckUser1(response);
        if (response.error === "false") {
          if (
            response?.isNewUser === "true" &&
            formSubmitType === "number_Check"
          ) {
            toast.success(response.errorMessage);
            dispatch(updateOnboardingState("OTPModal"));
            setOtpValue("")
            // setShowLoader(false)
            // dispatch(dispatchLoadingStop())
          } else {
            setIsExisting(true);
          }

          if (formSubmitType === "login") {
            Auth.login({
              ...response.Userdetails,
              token: response.accessToken,
            });
            dispatch(loginPost(true));
            dispatch(updateOnboardingState(""));

          }

          if (formSubmitType === "enterOtp") {
            toast.success(response.errorMessage);
            setInitialValues(values);
            dispatch(updateOnboardingState("signUp"));


          }
          if (formSubmitType === "reSendOTP") {
            toast.success(response.errorMessage);
            dispatch(dispatchLoadingStop());
            setInitialValues(values);
          }

          if (formSubmitType === "signUp") {
            toast.success(response.errorMessage);
            Auth.login({ ...response.userDetails, token: response.token });
            dispatch(updateOnboardingState(""));
            dispatch(dispatchLoadingStop());
          }

          if (onboardingState === "fpModal") {
            toast.success(response.errorMessage);
            dispatch(updateOnboardingState("OTPModal2"));
            dispatch(dispatchLoadingStop());
            setOtpValue("")

          }

          if (onboardingState === "OTPModal2") {
            dispatch(updateOnboardingState("resetPassword"));
            dispatch(dispatchLoadingStop());
          }

          if (onboardingState === "resetPassword") {
            toast.success(response.errorMessage);
            dispatch(updateOnboardingState(""));
            dispatch(dispatchLoadingStop());
          }
        } else {
          toast.error(response.errorMessage);
        }
        dispatch(dispatchLoadingStop());
      },
      (errors) => {
        toast.error("something went wrong!");
      }
    );
  };

  const checkUser = () => {
    if (checkUser1.isNewUser === "true") {
      dispatch(updateOnboardingState("login"));
    } else {
      dispatch(updateOnboardingState("fpModal"));
    }
  };

  useEffect(() => {
    if (onboardingState === "login") {
      setInitialValues({
        countryCode: "+91",
      });
    }
  }, [onboardingState]);

  useEffect(() => {
    if (loggedUser) {
      dispatch(loginPost(true));
    }
  }, [loggedUser?.id]);

  return (
    <>
      {!state.isUserloggedIn ? (
        <>
          <button
            type="button"
            className="cusbtn me-3"
            onClick={() => {
              setIsExisting(false);
              dispatch(updateOnboardingState("login"));
            }}
          >
            Log In
          </button>
          <button
            type="button"
            className="hoverbtn"
            onClick={() => {
              setIsExisting(false);
              dispatch(updateOnboardingState("login"));
            }}
          >
            Sign Up
          </button>
        </>
      ) : (
        <div className="d-flex">
          <span className="me-2">
            <img
              className="pointer"
              src="./assets/img/head_profile.svg"
              alt=""
              onClick={() => navigate("profile")}
            />
          </span>
          <span className="me-2">
            <div className="notification-view">
              <div className="notitxt">9</div>
              <img
                className="pointer"
                src="./assets/img/head_like.svg"
                alt=""
              />
            </div>
          </span>
          <span>
            <div className="notification-view">
              <div className="notitxt">9</div>
              <img
                className="pointer"
                src="./assets/img/head_cart.svg"
                alt=""
              />
            </div>
          </span>
        </div>
      )}

      {/* Login Modal */}
      <Modal
        show={onboardingState === "login"}
        onHide={() => dispatch(updateOnboardingState())}
        centered
        backdrop="static"
        keyboard={false}
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="modal_cus">
          <div className="modal-body">
            <h4 className="head4 text-center">Log In</h4>
            <p className="txt text-center">
              Enter your phone number to Proceed
            </p>
            <div className="mt-4">
              <Form
                autoComplete="off"
                onSubmit={(ev) =>
                  formSubmitHandler(ev, !isExisting ? "number_Check" : "login")
                }
              >
                <div className="input-grbox">
                  <label htmlFor="">Phone Number</label>
                  <div className="num-g-input">
                    <span className="selectbox-in">
                      <select
                        className="border-0 bg-transparent  shadow-none"
                        name="countryCode"
                        {...bindField("countryCode")}
                        value={values?.countryCode}
                      >
                        <option value="+1">+1</option>
                        <option value="+91">+91</option>
                      </select>
                    </span>
                    <input
                      type="text"
                      className="input_control ps-2"
                      name="mobileNumber"
                      {...bindField("mobileNumber")}
                    />
                  </div>
                  {errors?.mobileNumber && (
                    <span className="text-danger err small">
                      {errors?.mobileNumber}
                    </span>
                  )}

                  {isExisting && (
                    <>
                      <div className="input-grbox mt-4">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          className="input_control ps-2"
                          name="password"
                          {...bindField("password")}
                        />
                        <div className="link_next mt-2 text-end">
                          <button
                            type="button"
                            onClick={() =>
                              dispatch(updateOnboardingState("fpModal"))
                            }
                            className="link_txt"
                          >
                            Forgot Password?
                          </button>
                        </div>
                      </div>
                      {errors?.password && (
                        <span className="text-danger err small">
                          {errors?.password}
                        </span>
                      )}
                    </>
                  )}
                </div>

                <div className="btnCont-mt">
                  <button
                    type="submit"
                    disabled={!isValid()}
                    className="cusbtn w-100 text-center"
                  >
                    Log In
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* Sign Up modal */}
      <Modal
        show={onboardingState === "signUp"}
        onHide={() => dispatch(updateOnboardingState())}
        centered
        backdrop="static"
        keyboard={false}
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal_cus">
          <div className="modal-body">
            <h4 className="head4 text-center mb-5">Sign Up</h4>
            {/* <p className="txt text-center">Enter your phone number to procced</p> */}
            <div className="mt-4">
              <Form
                onSubmit={(ev) => formSubmitHandler(ev, "signUp")}
                autoComplete="off"
              >
                <div className="input-grbox">
                  <label htmlFor="">Phone Number</label>
                  <div className="num-g-input">
                    <span className="selectbox-in">
                      <select
                        {...bindField("countryCode")}
                        className="border-0 bg-transparent shadow-none "
                        value={values?.countryCode}
                      >
                        <option value="+1">+1</option>
                        <option value="+91">+91</option>
                      </select>
                    </span>
                    <input
                      type="number"
                      readOnly
                      name="mobileNumber"
                      {...bindField("mobileNumber")}
                      className="input_control ps-2"
                    />
                  </div>
                </div>
                {errors?.mobileNumber && (
                  <span className="text-danger err small">
                    {errors?.mobileNumber}
                  </span>
                )}

                <div className="input-grbox mt-4">
                  <label htmlFor="">Email ID</label>
                  <input
                    type="email"
                    className="input_control"
                    value={values.email}
                    {...bindField("email")}
                    name="email"
                  />
                </div>
                {errors?.email && (
                  <span className="text-danger err small">{errors?.email}</span>
                )}
                <div className="input-grbox mt-4">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    className="input_control"
                    autoComplete="off"
                    name="userName"
                    {...bindField("userName")}
                  />
                </div>
                {errors.userName && (
                  <span className="text-danger err small">
                    {errors.userName}
                  </span>
                )}

                <div className="input-grbox mt-4">
                  <label htmlFor="">Password</label>
                  <div className="passwor-icon-g">
                    <input
                      type={showPassword.password ? "text" : "password"}
                      autoComplete="off"
                      name="password"
                      {...bindField("password")}
                      className="input_control"
                    />

                    {
                      <span className="icon-password">
                        <img
                          src={
                            showPassword.password
                              ? "./assets/img/eyesPassword.svg"
                              : "./assets/img/hide_password.svg"
                          }
                          alt="eyes"
                          onClick={() =>
                            setshowPassword((preValues) => ({
                              ...preValues,
                              password: !showPassword.password,
                            }))
                          }
                        />
                      </span>
                    }
                  </div>
                </div>
                {errors.password && (
                  <span className="text-danger err small">
                    {errors.password}arrowBack
                  </span>
                )}

                <div className="input-grbox mt-4">
                  <label htmlFor="">Short Interests Survey (Optional)</label>
                  <input type="text" className="input_control" />
                </div>
                <div className="checkbox-group d-flex mt-4">
                  <label className="check-container">
                    <input
                      type="checkbox"
                      onChange={() => setTermsConditions(!termsConditions)}
                      className="d-none"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label>
                    {" "}
                    By creating an account, I accept the{" "}
                    <span className="label-span">
                      {" "}
                      <Link to="https://www.google.com/" target="_blank">
                        Terms and conditions
                      </Link>{" "}
                    </span>
                  </label>
                </div>
                <div className="btnCont-mt">

                  <button
                    type="submit"
                    disabled={!termsConditions && !isValid()}
                    className="cusbtn w-100 text-center"
                  >
                    Continue
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* OTP verfication Modal */}
      <Modal
        show={onboardingState === "OTPModal" || onboardingState === "OTPModal2"}
        onHide={() => dispatch(updateOnboardingState())}
        centered
        backdrop="static"
        keyboard={false}
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal_cus">
          <div className="modal-body">
            <a className="arrow_back d-inline" onClick={() => checkUser()}>
              <img src="./assets/img/arrowBack.svg" alt="arrow back" />
            </a>
            <h4 className="head4 text-center"> Enter OTP</h4>
            <p className="txt text-center">Enter your code</p>
            <Form
              onSubmit={(ev) => formSubmitHandler(ev, "enterOtp")}
              autoComplete="off"
            >
              <div className="mt-4 otp_box">
                <OtpInput
                  value={otpValue}
                  onChange={setOtpValue}
                  numInputs={6}
                  renderSeparator={<span>&nbsp;</span>}
                  renderInput={(props) => (
                    <input {...props} className="otp_control" />
                  )}
                />
                <div className="btnCont-mt">
                  <button
                    disabled={otpValue?.length !== 6}
                    type="submit"
                    className="cusbtn me-3 w-100 "
                  >
                    Verify & proceed
                  </button>
                  <div className="link_next mt-2 text-center">
                    <button
                      type="button"
                      // type="submit"
                      // onClick={() => dispatch(updateOnboardingState("fpModal")}
                      onClick={(ev) => formSubmitHandler(ev, "reSendOTP")}
                      className="link_txt"
                    >
                      Resend OTP
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      {/* Forgot passward Modal */}
      <Modal
        show={onboardingState === "fpModal"}
        onHide={() => dispatch(updateOnboardingState())}
        centered
        backdrop="static"
        keyboard={false}
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal_cus">
          <div className="modal-body">
            <a
              className="arrow_back d-inline"
              onClick={() => dispatch(updateOnboardingState("login"))}
            >
              <img src="./assets/img/arrowBack.svg" alt="arrow back" />
            </a>
            <h4 className="head4 text-center">Forgot Password</h4>
            <p className="txt text-center">
              Enter your phone number to proceed
            </p>
            <div className="mt-4">
              <Form
                onSubmit={(ev) => formSubmitHandler(ev, "fpModal")}
                autoComplete="off"
              >
                <div className="input-grbox">
                  <label htmlFor="">Phone Number</label>
                  <div className="num-g-input">
                    <span className="selectbox-in">
                      <select
                        className="border-0 bg-transparent  shadow-none"
                        name="countryCode"
                        value={values.countryCode}
                        {...bindField("countryCode")}
                      >
                        <option value="+1">+1</option>
                        <option value="+91">+91</option>
                      </select>
                    </span>
                    <input
                      type="number"
                      maxLength="10"
                      className="input_control ps-2"
                      name="mobileNumber"
                      value={values.mobileNumber}
                      {...bindField("mobileNumber")}
                    />
                  </div>

                  {errors.mobileNumber && (
                    <span className="text-danger err small">
                      {errors.mobileNumber}
                    </span>
                  )}
                </div>
                <div className="btnCont-mt">
                  <button
                    disabled={!isValid()}
                    type="submit"
                    className="cusbtn w-100 text-center"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>

      {/* Reset Password */}
      <Modal
        show={onboardingState === "resetPassword"}
        onHide={() => dispatch(updateOnboardingState())}
        centered
        backdrop="static"
        keyboard={false}
        className="center-close-icon"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="modal_cus">
          <div className="modal-body">
            <a
              className="arrow_back d-inline"
              onClick={() => dispatch(updateOnboardingState("enterOtp"))}
            >
              <img src="./assets/img/arrowBack.svg" alt="arrow back" />
            </a>
            <h4 className="head4 text-center">Reset Password</h4>
            <p className="txt text-center">Enter your new password</p>
            <div className="mt-4">
              <Form
                onSubmit={(ev) => formSubmitHandler(ev, "resetPassword")}
                autoComplete="off"
              >
                <div className="input-grbox mt-4">
                  <label htmlFor="">New Password</label>
                  <div className="passwor-icon-g">
                    <input
                      type={showPassword.password ? "text" : "password"}
                      autoComplete="off"
                      name="password"
                      {...bindField("password")}
                      className="input_control"
                    />

                    {
                      <span className="icon-password">
                        <img
                          src={
                            showPassword.password
                              ? "./assets/img/eyesPassword.svg"
                              : "./assets/img/hide_password.svg"
                          }
                          alt="eyes"
                          onClick={() =>
                            setshowPassword((preValues) => ({
                              ...preValues,
                              password: !showPassword.password,
                            }))
                          }
                        />
                      </span>
                    }
                  </div>
                </div>
                {errors.password && (
                  <span className="text-danger err small">
                    {errors.password}
                  </span>
                )}

                <div className="input-grbox mt-4">
                  <label htmlFor="">Confrim Password</label>
                  <div className="passwor-icon-g">
                    <input
                      type={showPassword.password ? "text" : "password"}
                      autoComplete="off"
                      name="confirm_password"
                      {...bindField("confirm_password")}
                      className="input_control"
                    />

                    {
                      <span className="icon-password">
                        <img
                          src={
                            showPassword.password
                              ? "./assets/img/eyesPassword.svg"
                              : "./assets/img/hide_password.svg"
                          }
                          alt="eyes"
                          onClick={() =>
                            setshowPassword((preValues) => ({
                              ...preValues,
                              password: !showPassword.password,
                            }))
                          }
                        />
                      </span>
                    }
                  </div>
                </div>

                <div className="btnCont-mt">

                  <button
                    type="submit"
                    disabled={!isValid()}
                    className="cusbtn w-100 text-center"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Onboarding;
