import { RouterProvider, createHashRouter } from "react-router-dom";
import "./App.scss";
import Home from "./component/home/Home";
import Layout from "./component/layout/Layout";
// import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Restaurant from "./component/restaurant/Restaurant";
import RestaurantMenu from "./component/restaurant/RestaurantMenu";
import Checkout from "./component/checkout/Checkout";
import Profile from "./component/profile/Profile";
import "react-toastify/dist/ReactToastify.css";
import OrderStatus from "./component/order/OrderStatus";
import AboutUs from "./component/aboutus/AboutUs";
import ContactUs from "./component/contactus/ContactUs";
import { useSelector } from "react-redux";
import Spinner from "./component/widgets/Spinner";
import CheckOut from "./component/checkout/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_wDlYbBsqPdfD9OfHS84AeZhS");

export const router = createHashRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "aboutus",
        element: <AboutUs />,
      },
      {
        path: "contactus",
        element: <ContactUs />,
      },
      {
        path: "restaurant",
        element: <Restaurant />,
      },
      {
        path: "restaurantmenu",
        element: <RestaurantMenu />,
      },

      {
        path: "checkout",
        element: (
          <Elements stripe={stripePromise}>
            <Checkout />
          </Elements>
        ),
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "order-status/:id",
        element: <OrderStatus />,
      },
      {
        path: "checkout",
        element: <CheckOut />,
      },
    ],
  },
]);

function App() {
  // const [showLoader, setShowLoader] = useState(false);
  const state = useSelector((state) => state);
  return (
    <>
      {/* {showLoader && (
        <div className="main-loader">
          <Spinner
            animation="border"
            className="loader"
            variant="light"
          ></Spinner>
        </div>
      )} */}
      {/* {state.auth} */}
      {state.auth.isLoading && <Spinner />}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
