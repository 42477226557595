import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Onboarding from "./Onboarding";

const Header = () => {
    return (
        <div>
            <header>
                <div className="cus_container">
                    <Navbar expand="lg" className="navbar-light bg-transparent p-0">
                        <Container fluid className="px-0">
                            <Navbar.Brand href="#" className="navbar-brand">
                                <img
                                    src="./assets/img/header_logo.svg"
                                    alt="logo"
                                    className="brand logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle
                                aria-controls="navbarScroll"
                                className=" px-2 py-1  shadow-none"
                            />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav className="mx-auto my-2 my-lg-0 " navbarScroll>
                                    <ul className="navbar-nav m-lg-auto mb-2 mb-lg-0 mt-0">
                                        <Navbar.Toggle as={"li"} bsPrefix=" ">
                                            <NavLink to="">Home</NavLink>
                                        </Navbar.Toggle>
                                        <Navbar.Toggle as={"li"} bsPrefix=" ">
                                            <NavLink to="aboutus">About Us</NavLink>
                                        </Navbar.Toggle>
                                        <Navbar.Toggle as={"li"} bsPrefix=" ">
                                            <NavLink to="contactus">Contact Us</NavLink>
                                        </Navbar.Toggle>
                                    </ul>
                                </Nav>
                                <div className="header-btn">
                                    <Onboarding />
                                </div>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
            </header>

            {/* <div className="modal_cus">
                <div className="modal-body">
                    <h4 className="head4 text-center">Log In</h4>
                    <p className="txt text-center">Enter your phone number to procced</p>
                    <div className="mt-4">
                        <form>
                            <div className="input-grbox">
                                <label for="">Phone Number</label>
                                <div className="num-g-input">
                                    <span className="selectbox-in">
                                        <select className="border-0 bg-transparent  shadow-none ">
                                            <option>+1</option>
                                            <option>+91</option>
                                        </select>
                                    </span>
                                    <input type="number" className="input_control ps-2" />
                                </div>
                            </div>

                            <div className="btnCont-mt">
                                <button type="button" className="cusbtn w-100 text-center"
                                >Log
                                    In</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}

            {/*  <div className="modal_cus">
        <div className="modal-body">
            <h4 className="head4 text-center">Enter OTP</h4>
            <p className="txt text-center">Enter your phone number to procced</p>
            <div className="mt-4">
                <form>
                    <label for="">Enter OTP</label>
                    <div className="multiple-input-otp">
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                    </div>
                    <div className="btnCont-mt">
                        <button className="cusbtn w-100 text-center">Verify & Proceed</button>
                        <div className="link_next mt-3 text-center">
                            <a href="#" className="link_txt">Resend OTP</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div> */}

            {/* <div className="modal_cus">
        <div className="modal-body">
            <h4 className="head4 text-center mb-5">Sign Up</h4>
            <div className="mt-4">
                <form>
                    <div className="input-grbox">
                        <label for="">Phone Number</label>
                        <div className="num-g-input">
                            <span className="selectbox-in">
                                <select className="border-0 bg-transparent  shadow-none ">
                                    <option>+1</option>
                                    <option>+91</option>
                                </select>
                            </span>
                            <input type="number" className="input_control ps-2"/>
                        </div>
                    </div>
                    <div className="input-grbox mt-4">
                        <label for="">Email ID</label>
                        <input type="email" className="input_control"/>

                    </div>
                    <div className="input-grbox mt-4">
                        <label for="">Name</label>
                        <input type="text" className="input_control"/>

                    </div>
                    <div className="input-grbox mt-4">
                        <label for="">Password</label>
                        <div className="passwor-icon-g">
                            <input type="password" className="input_control"/>
                            <span className="icon-password">
                                <img src="./assets/img/eyesPassword.svg" alt="eyes"/>
                            </span>
                        </div>

                    </div>
                    <div className="input-grbox mt-4">
                        <label for="">Short Interests Survey (Optional)</label>
                        <input type="text" className="input_control"/>

                    </div>
                    <div className="checkbox-group d-flex mt-4">
                        <label className="check-container">
                            <input type="checkbox" className="d-none"/>
                            <span className="checkmark"></span>

                        </label>
                        <label> By creating an account, I accept the <span className="label-span"> <a href="">
                                    Terms and conditions
                                </a> </span></label>
                    </div>
                    <div className="btnCont-mt">
                        <button type="button" className="cusbtn w-100 text-center">Continue</button>

                    </div>
                </form>
            </div>
        </div>
    </div>

    <div className="modal_cus">
        <div className="modal-body">
            <h4 className="head4 text-center">Log In</h4>
            <p className="txt text-center">Enter your phone number to procced</p>
            <div className="mt-4">
                <form>
                    <div className="input-grbox">
                        <label for="">Phone Number</label>
                        <div className="num-g-input">
                            <span className="selectbox-in">
                                <select className="border-0 bg-transparent  shadow-none ">
                                    <option>+1</option>
                                    <option>+91</option>
                                </select>
                            </span>
                            <input type="number" className="input_control ps-2"/>
                        </div>
                    </div>
                    <div className="input-grbox mt-4">
                        <label for="">Password</label>
                        <div className="passwor-icon-g">
                            <input type="password" className="input_control"/>
                            <span className="icon-password">
                                <img src="./assets/img/eyesPassword.svg" alt="eyes"/>
                            </span>
                        </div>

                    </div>

                    <div className="link_next mt-2 text-end">
                        <a href="#" className="link_txt">Forgot Password?</a>
                    </div>
                    <div className="btnCont-mt">
                        <button type="button" className="cusbtn w-100 text-center">Log
                            In</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div className="modal_cus">
        <div className="modal-body">
            <h4 className="head4 text-center">Forgot Password</h4>
            <p className="txt text-center">Enter your phone number to procced</p>
            <div className="mt-4">
                <form>
                    <div className="input-grbox">
                        <label for="">Phone Number</label>
                        <div className="num-g-input">
                            <span className="selectbox-in">
                                <select className="border-0 bg-transparent  shadow-none ">
                                    <option>+1</option>
                                    <option>+91</option>
                                </select>
                            </span>
                            <input type="number" className="input_control ps-2"/>
                        </div>
                    </div>

                    <div className="btnCont-mt">
                        <button type="button" className="cusbtn w-100 text-center">Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div className="modal_cus">
        <div className="modal-body">
            <h4 className="head4 text-center">Enter OTP</h4>
            <p className="txt text-center">Enter your phone number to procced</p>
            <div className="mt-4">
                <form>
                    <label for="">Enter OTP</label>
                    <div className="multiple-input-otp">
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                        <input type="text" className="input_control" maxlength="1"/>
                    </div>
                    <div className="btnCont-mt">
                        <button type="button" className="cusbtn w-100 text-center">Verify & Proceed</button>
                        <div className="link_next mt-3 text-center">
                            <a href="#" className="link_txt">Resend OTP</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div className="modal_cus">
        <div className="modal-body">
            <h4 className="head4 text-center">Reset Password</h4>
            <p className="txt text-center">Enter your new password</p>
            <div className="mt-4">
                <form>
                    <div className="input-grbox">
                        <label for="">Password</label>
                        <div className="passwor-icon-g">
                            <input type="password" className="input_control"/>
                            <span className="icon-password">
                                <img src="./assets/img/eyesPassword.svg" alt="eyes"/>
                            </span>
                        </div>

                    </div>
                    <div className="input-grbox mt-4">
                        <label for="">Password</label>
                        <div className="passwor-icon-g">
                            <input type="password" className="input_control"/>
                            <span className="icon-password">
                                <img src="./assets/img/eyesPassword.svg" alt="eyes"/>
                            </span>
                        </div>

                    </div>
                    <div className="btnCont-mt">
                        <button className="cusbtn w-100 text-center">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div> */}
        </div>
    );
};

export default Header;
