import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { http } from "../../helpers/http";
import moment from "moment";
import Auth from "../../auth/Auth";

function OrderStatus() {
  const { id: orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const navigate = useNavigate();

  const getOrderStatus = () => {
    http(
      {
        method: "post",
        url: `trackOrders`,
        isSecure: true,
        body: {
          orderId,
        },
      },
      (res) => {
        setOrderDetails(res.ordersDetails);
      },
      (res) => {}
    );
  };

  useEffect(() => {
    if (!Auth.isUserAuthenticated()) {
      navigate("/");
      return;
    }
    getOrderStatus();
  }, []);

  return (
    <>
      <main>
        <div className="restaurant_inner_bg needmin-hmain">
          <div className="cus_container">
            <h1 className="head1 text-center">Order Status</h1>
          </div>
        </div>
      </main>
      <section className="orderStatus">
        <div className="cus_container">
          <div className="pointer" onClick={() => navigate("/profile")}>
            <span>
              <img
                src="./assets/img/arrowBack.svg"
                alt="profile"
                className="profile_img"
              />
            </span>
            <span className="ms-2">Back</span>
          </div>
          <h4 className="head4 mt-4 bLine-five">My Order</h4>
          <div className="white_box mt-4 p-4">
            <h5 className="head5 mb-3">{orderDetails.displayOrderId}</h5>
            <div className="row">
              <div className="col-4">
                <h6 className="head6 mb-1">{orderDetails.outletName}</h6>
                {orderDetails.dishes?.map((item) => (
                  <p className="txt mb-1 light_b">{item.dishplayDish}</p>
                ))}
                <p className="txt mb-0">
                  {orderDetails.orderItems}
                  {orderDetails.orderItems > 1 ? " Items" : " Item"}, To pay:{" "}
                  {orderDetails.netAmount}
                </p>
              </div>
              <div className="col-8">
                <p className="txt d-flex justify-content-between mx-4">
                  {orderDetails.receivedTime && (
                    <span>
                      {moment(orderDetails.receivedTime).format("LT")}
                    </span>
                  )}
                  {orderDetails.orderConfirmedTime && (
                    <span>
                      {moment(orderDetails.orderConfirmedTime).format("LT")}
                    </span>
                  )}
                  {orderDetails.orderPickedupTime && (
                    <span>
                      {moment(orderDetails.orderPickedupTime).format("LT")}
                    </span>
                  )}
                </p>
                <div className="trckbrd d-flex justify-content-between">
                  <div className="order-tracking">
                    <div
                      className={`circle-tract ${
                        orderDetails.orderReceived ? "active_tr" : ""
                      }`}
                    ></div>
                    <p
                      className={`txt mt-2 ${
                        orderDetails.orderReceived ? "light_b" : ""
                      }`}
                    >
                      Order Received
                    </p>
                  </div>
                  <div className="order-tracking">
                    <div
                      className={`circle-tract ${
                        orderDetails.orderConfirmed ? "active_tr" : ""
                      }`}
                    ></div>
                    <p
                      className={`txt mt-2 ${
                        orderDetails.orderConfirmed ? "light_b" : ""
                      }`}
                    >
                      Order Confirmed
                    </p>
                  </div>
                  <div className="order-tracking">
                    <div
                      className={`circle-tract ${
                        orderDetails.orderPickedUp ? "active_tr" : ""
                      }`}
                    ></div>
                    <p
                      className={`txt mt-2 ${
                        orderDetails.orderPickedUp ? "light_b" : ""
                      }`}
                    >
                      Order Picked
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OrderStatus;
