import {
  CEHECK_ENTER_NUMBER,
  ENTER_OTP,
  FETCH_CUISINES,
  FORGET_PASSWORD,
  LOADING_START,
  LOADING_STOP,
  LOGIN_DATA,
  RESTAURANT_LIST,
  SIGN_UP,
  UPDATE_ONBOARDING_STATE,
  USER_LOGIN,
} from "../actionTypes/authTypes";

const initalState = {
  getCuisinesData: [],
  loginPost: [],
  isUserloggedIn: false,
  enterOtp: [],
  signUp: [],
  userLogin: [],
  restaurantList: [],
  forgetPassword: [],
  isLoading: false,
  onboardingState: "",
};

export function authReducer(state = initalState, action) {
  switch (action.type) {
    case CEHECK_ENTER_NUMBER:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case FETCH_CUISINES:
      return {
        ...state,
        // ...action.payload,
        getCuisinesData: action.payload,
      };
    case LOGIN_DATA:
      return {
        ...state,
        isUserloggedIn: action.payload,
      };
    case ENTER_OTP:
      return {
        ...state,
        enterOtp: action.payload.value,
      };
    case SIGN_UP:
      return {
        ...state,
        signUp: action.payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        userLogin: action.payload,
      };
    case RESTAURANT_LIST:
      return {
        ...state,
        restaurantList: action.payload,
      };
    case FORGET_PASSWORD:
      return {
        ...state,
        forgetPassword: action.payload,
      };
    case LOADING_START:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_ONBOARDING_STATE:
      return {
        ...state,
        onboardingState: action.payload,
      };

    default:
      return state;
  }
}
