import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import { placeholderHandler } from "../../helpers/help";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import {
    dispatchLoadingStart,
    dispatchLoadingStop,
    restaurantList,
} from "../../redux/actions/authActions";
import useCurrentPosition from "../../helpers/customHooks/useCurrentPosition";
const Nearbyrest = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { latitudeData, longitudeData } = useCurrentPosition();
    const state = useSelector(
        (state) => state.auth.restaurantList?.listRestaurants
    );
    useEffect(() => {
        if (latitudeData && longitudeData) {
            restaurant();
            dispatch(dispatchLoadingStart());
        }
    }, [latitudeData, longitudeData]);

    const restaurant = () => {
        http(
            {
                method: "POST",
                url: `listRestaurant`,
                isSecure: true,
                body: {
                    latitude: latitudeData,
                    longitude: longitudeData,
                    type: "Take Out",
                },
            },
            (res) => {
                dispatch(restaurantList(res));
                dispatch(dispatchLoadingStop());
            }
        );
    };

    const Restaurant_menu = () => {
        navigate("/restaurantmenu");
    };
    return (
        <>
            <section className="restaurants_near">
                <div className="cus_container">
                    <h4 className="head4 text-center">Best</h4>
                    <h3 className="head3 text-center">Restaurants Near By</h3>

                    <div className="restaurants_container">
                        <div className="restaurants_group">
                            {state &&
                                state.map((item, index) => {
                                    return (
                                        <Link
                                            to="/restaurantmenu"
                                            className="restaurants_item"
                                            state={{ item }}
                                            key={index}
                                        >
                                            <div className="res_img">
                                                <img
                                                    onError={placeholderHandler}
                                                    src={item.restaurantImage}
                                                    alt="restaurant"
                                                    className="restaurant-img"
                                                />
                                            </div>
                                            <div className="res_content">
                                                <div className="my-2 d-flex align-items-center justify-content-between">
                                                    <h6 className="head6">{item.restaurantName}</h6>
                                                    {item?.emergency_close === 0 ? (
                                                        <div className="btn_res open">Open</div>
                                                    ) : (
                                                        <div className="btn_res close">Close</div>
                                                    )}
                                                </div>
                                                <p className="txt my-2">
                                                    {item.outlet.map((food) => {
                                                        return food.foodTag.map((tag, index) => {
                                                            return (
                                                                <span className="me-2" key={"tag" + index}>
                                                                    {

                                                                    }
                                                                    {tag.itemName},
                                                                </span>
                                                            );
                                                        });
                                                    })}
                                                </p>

                                                <div className="ratting_sec d-flex align-items-center justify-content-between">
                                                    <div className="review_start">
                                                        <Rating
                                                            initialValue={item.averageReview}
                                                            readonly
                                                        />
                                                    </div>
                                                    <div className="heart-img">
                                                        <img
                                                            src="./assets/img/heart_gray.svg"
                                                            alt="grat heart"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                        </div>
                    </div>
                    {
                        console.log(state, 'adasd')
                    }
                    {
                        state && state?.length > 0 && (
                            <div className="text-center middle-line-RL mt-5">
                                <Link to="/restaurant" className="cusbtn btnsize17 d-block m-auto">
                                    View More
                                </Link>
                            </div>
                        )
                    }

                </div>
            </section>
        </>
    );
};
export default Nearbyrest;
