
import React from 'react';
import { Modal } from "react-bootstrap";
import moment from "moment";



const Restauranttime = (props) => {
    const { openRestTimeModal, setOpenRestTime } = props;
    return (
        <Modal
            show={openRestTimeModal.state}
            onHide={() => {
                setOpenRestTime(false)
            }}
            centered
            className="logoutmodal center-close-icon"
        >
            <Modal.Header closeButton></Modal.Header>
            <div className="bodyModal">
                <h5 className="head5 text-center">Store Hours</h5>
                <div className="time_modbox  mt-4">
                    {
                        openRestTimeModal?.data?.length > 0 > 0 ?
                            openRestTimeModal?.data?.map((item, index) => (

                                <div class="d-flex justify-content-between mb-2">
                                    {/* {moment(
                              restoDetails?.restaurant_business_day?.from_time,
                              "HH:mm"
                            ).format("hh:mm")} */}
                                    <div className="col-left">{item.day}</div>
                                    <div className="col-right">{moment(item?.from_time, " h:mm a").format(" h:mm a")} -{moment(item?.to_time, " h:mm a").format(" h:mm a")}</div>
                                    {/* <div className="col-right">{moment(item?.to_time, "HH:mm").format("hh:mm")}</div> */}
                                </div>
                            )) : 'No data found'
                    }

                    {/* <div class="d-flex justify-content-between mb-2">
                        <div className="col-left">Sunday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div className="col-left">Monday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div className="col-left">Tuesday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div className="col-left">Wednesday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div className="col-left">Thursday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div className="col-left">Friday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div className="col-left">Saturday</div>
                        <div className="col-right">12:00 am - 11:59 pm</div>
                    </div> */}
                </div>
            </div>
        </Modal>
    );

};



export default Restauranttime;
