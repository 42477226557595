import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import { formatPhoneNumber } from "../../helpers/help";
import Logout from "../modals/Logout";
import RateUs from "../modals/RateUs";
import {
  dispatchLoadingStart,
  dispatchLoadingStop,
} from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import Auth from "../../auth/Auth";

const Profile = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const handleShow = () => setOpenLogoutModal(true);
  const [rateUsModal, setRateUsModal] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [orderHistory, setOrderHistory] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Auth.isUserAuthenticated()) {
      navigate("/");
      return;
    }
    getProfileData();
    getOrderHistory();
  }, []);

  const getProfileData = () => {
    dispatch(dispatchLoadingStart());
    http(
      {
        method: "GET",
        url: `getProfile`,
        isSecure: true,
      },
      (res) => {
        if (res.error === "false") setProfileData(res.usersDetails);
        dispatch(dispatchLoadingStop());
      },
      (res) => {}
    );
  };

  const getOrderHistory = () => {
    http(
      {
        method: "POST",
        url: `listPastOrders`,
        isSecure: true,
        body: { pageNumber: 1 },
      },
      (res) => {
        res.error === "false" && setOrderHistory(res.pastOrders);
      },
      (res) => {}
    );
  };

  return (
    <>
      <main>
        <div className="restaurant_inner_bg needmin-hmain">
          <div className="cus_container">
            <h1 className="head1 text-center">Profile</h1>
          </div>
        </div>
      </main>
      <section className="profile">
        <div className="cus_container">
          <div className="white_box">
            <div className="view_profile-item">
              <div className="Profile_info">
                <img
                  src="./assets/img/profile.png"
                  alt="profile"
                  className="profile_img"
                />
                <div>
                  <div className="profile_name d-flex align-items-center">
                    <h5 className="head5 mb-0 me-2">{profileData.userName}</h5>
                    <img src="./assets/img/edit_red.svg" alt="edit icon" />
                  </div>
                  <div className="Profile_num d-flex align-items-center mt-2">
                    <img src="./assets/img/phone.svg" alt="edit icon" />
                    <p className="txt mb-0 ms-2 light_b">
                      {formatPhoneNumber(
                        profileData.countryCode,
                        profileData.mobileNumber
                      )}
                    </p>
                  </div>
                  <div className="Profile_num d-flex align-items-center mt-2">
                    <img src="./assets/img/iconAddtr.svg" alt="edit icon" />
                    <p className="txt mb-0 ms-2 light_b">{profileData.email}</p>
                  </div>
                </div>
              </div>
              <div className="profile_tab mt-lg-5 mt-4">
                <span className=" me-3 cusbtn">Order History</span>
                <span className="tab-item-tr pointer " onClick={handleShow}>
                  Log Out
                </span>
              </div>
            </div>
            {orderHistory.length ? (
              orderHistory.map((order) => (
                <div key={order.orderId} className="view_profile-bg-gray mt-3">
                  <div className="d-flex align-items-center  justify-content-between">
                    <h5 className="head5">{order.orderDetails.outletName}</h5>
                    <h5 className="head5">
                      {order.orderDetails.displayNetAmount}
                    </h5>
                  </div>
                  {order.orderDetails.dishes.map((dish, index) => (
                    <p
                      key={`${order.orderId}-${index}`}
                      className="txt mb-1 light_b"
                    >
                      {dish.dishplayDish}
                    </p>
                  ))}
                  {/* <p className="txt mb-1 light_b">
                  <sup>*</sup>Less Spicy Please
                </p> */}
                  <div className="d-flex align-items-center  justify-content-between">
                    <p className="txt mb-0 light_b">
                      Qty: {order.orderDetails.displayTotalItems}
                    </p>
                    {(order.payment_status === "succeeded" &&
                      order.isdelivered === "false") ||
                      (true && (
                        <Link
                          to={`/order-status/${order.orderId}`}
                          className="mb-0 cusbtn"
                        >
                          Track Order
                        </Link>
                      ))}
                    {order.payment_status === "succeeded" &&
                      order.isRated === "false" && (
                        <span
                          className="mb-0 cusbtn"
                          onClick={() => {
                            setRateUsModal(true);
                            setSelectedItem(order);
                          }}
                        >
                          Rate Us
                        </span>
                      )}
                  </div>
                </div>
              ))
            ) : (
              <p className="mt-2">No Order History</p>
            )}
          </div>
        </div>
      </section>

      {/* modal section */}
      {/* Log out  modal*/}
      <Logout
        openLogoutModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
      />

      {/* rate us modal */}
      <RateUs
        rateUsModal={rateUsModal}
        setRateUsModal={setRateUsModal}
        selectedItem={selectedItem}
      />
    </>
  );
};
export default Profile;
