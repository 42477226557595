import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <footer>
            <div className="cus_container">
                <div className="footer-top">
                    <div className="d-lg-flex justify-content-between align-items-center">
                        <div className="col-left">
                            <h3 className="head3">
                                Have Question in mind?
                                <br />
                                <span> Let us Help You</span>
                            </h3>
                        </div>
                        <div className="col-right mt-3">
                            <div className="inputGroup d-flex align-items-center">
                                <input type="text" className="input_control bg-white" placeholder="Enter your question" />
                                <button className="cusbtn ms-2">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="footer-logo">
                            <img src="./assets/img/footerlogo.svg" alt="logo" />
                            {/* <img src="/assets/img/footerlogo.svg" alt='logo' /> */}
                        </div>
                        <div className="footer-widget">
                            <ul>
                                <li className="widget-title">
                                    <Link to="">Home</Link>
                                </li>
                                <li className="widget-title">
                                    <Link to="/restaurant">Restaurants</Link>
                                </li>
                                <li className="widget-title">
                                    <Link to="aboutus">About Us</Link>
                                </li>
                                <li className="widget-title">
                                    <Link to="contactus">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-social">
                            <ul>
                                <li className="social-title">
                                    <a href="https://www.google.com/" target='_blank'>
                                        <img src="./assets/img/facebook.svg" alt="facebook" className="social-link" />
                                    </a>
                                </li>
                                <li className="social-title">
                                    <a href="https://www.google.com/" target='_blank'>
                                        <img src="./assets/img/instagram.svg" alt="instagram" className="social-link" />
                                    </a>
                                </li>
                                <li className="social-title">
                                    <a href="https://www.google.com/" target='_blank'>
                                        <img src="./assets/img/twitter.svg" alt="twitter" className="social-link" />
                                    </a>
                                </li>
                                <li className="social-title">
                                    <a href="https://www.google.com/" target='_blank'>
                                        <img src="./assets/img/youtube.svg" alt="youtube" className="social-link" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </footer>
    );
};

export default Footer;

