import { useEffect, useState } from "react";

function useCurrentPosition() {
  const [data, setData] = useState({ latitudeData: null, longitudeData: null });
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setData({
        latitudeData: position.coords.latitude.toFixed(3),
        longitudeData: position.coords.longitude.toFixed(3),
      });
    });
  }, []);

  return { latitudeData: data.latitudeData, longitudeData: data.longitudeData };
}

export default useCurrentPosition;
