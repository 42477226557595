export const allowNumbersWithNoSpaces = (value) => {
  return value.match(/^[0-9]*$/);
};

export const placeholderHandler = (event) => {
  return (event.target.src =
    process.env.PUBLIC_URL + "./assets/img/restaurant.png");
};

export const formatPhoneNumber = (countryCode, phoneNumber) => {
  if (!countryCode || !phoneNumber) return "";
  const result = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
    3,
    6
  )}-${phoneNumber.substring(6, phoneNumber.length)}`;
  return `${countryCode} ${result}`;
};
