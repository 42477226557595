import React, { useEffect, useState } from "react";
import Foodtag from "../common/Foodtag";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../helpers/http";
import { useDispatch, useSelector } from "react-redux";
import { placeholderHandler } from "../../helpers/help";
import { restaurantList } from "../../redux/actions/authActions";
import { Rating } from "react-simple-star-rating";
import {
  dispatchLoadingStart,
  dispatchLoadingStop,
} from "../../redux/actions/authActions";
import useCurrentPosition from "../../helpers/customHooks/useCurrentPosition";

const Restaurant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { latitudeData, longitudeData } = useCurrentPosition();
  const state = useSelector(
    (state) => state.auth.restaurantList.listRestaurants
  );
  const [view, setView] = useState(false);

  const restaurant = () => {
    dispatch(dispatchLoadingStart());
    http(
      {
        method: "POST",
        url: `listRestaurant`,
        isSecure: true,
        body: {
          latitude: latitudeData,
          longitude: longitudeData,
          type: "Take Out",
        },
      },
      (res) => {
        dispatch(restaurantList(res));
        dispatch(dispatchLoadingStop());
      }
    );
  };

  const restaurant_menu = () => {
    navigate("/restaurantmenu");
  };

  useEffect(() => {
    if (latitudeData && longitudeData) {
      // dispatch(dispatchLoadingStart())
      restaurant();
    }
  }, [latitudeData, longitudeData]);

  return (
    <>
      <main>
        <div className="restaurant_inner_bg">
          <div className="cus_container">
            <h1 className="head1 text-center">Restaurants</h1>
            <form>
              <div className="inputGroup text-center">
                <input
                  type="text"
                  className="input_control input-inside-img bg-white "
                />
              </div>
            </form>
          </div>
        </div>
      </main>

      <section className="restaurantsNear">
        <div className="cus_container">
          <div className="d-flex">
            {/* filter box */}
            <Foodtag />
            {/* filter box end */}
            <div className="restaurant_view_all">
              <Link to="/" className="arrow_back d-inline">
                <img src="./assets/img/arrowBack.svg" alt="arrow back" />
                Back
              </Link>
              <div className="d-flex justify-content-between align-items-center">
                <div className="col_left">
                  <h4 className="head4 mt-4">Restaurants Near By</h4>
                </div>
                <div className="col-right">
                  <div className="custab_btn">
                    <button
                      onClick={() => setView(false)}
                      className={`${view === false ? "active" : ""} tabbtn`}
                    >
                      <img
                        src="./assets/img/unGrid_white.svg"
                        alt="arrow back"
                        className="Active_grout-item"
                      />
                      <img
                        src="./assets/img/unGrid.svg"
                        alt="arrow back"
                        className="deActive_grout-item"
                      />
                    </button>
                    <button
                      onClick={() => setView(true)}
                      className={`${view ? "active" : ""} tabbtn`}
                    >
                      <img
                        src="./assets/img/grid_item_img.svg"
                        alt="grid active"
                        className="Active_grout-item"
                      />
                      <img
                        src="./assets/img/grid_item_img_black.svg"
                        alt="grid deactive"
                        className="deActive_grout-item"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="view_data_resaurant">
                {view === false ? (
                  <div className="grid_item_single">
                    <div className="restaurants_container">
                      <div className="restaurants_group">
                        {state &&
                          state.map((item, index) => {
                            return (
                              <Link
                                key={index}
                                state={{ item }}
                                className="restaurants_item"
                                to="/restaurantmenu"
                              >
                                <div className="res_img resimg_fix">
                                  <img
                                    onError={placeholderHandler}
                                    src={item.restaurantImage}
                                    alt="restaurant"
                                    className="restaurant-img"
                                  />
                                </div>
                                <div className="res_content dgrid">
                                  <div className="my-2 d-flex align-items-center justify-content-between">
                                    <h6 className="head6">
                                      {item.restaurantName}
                                    </h6>
                                    {item?.emergency_close === 0 ? (
                                      <div className="btn_res open">Open</div>
                                    ) : (
                                      <div className="btn_res close">Close</div>
                                    )}
                                  </div>
                                  <p className="txt my-2">
                                    {item.outlet.map((food) => {
                                      return food.foodTag.map((tag, index) => {
                                        return (
                                          <span
                                            className="me-2"
                                            key={"tag" + index}
                                          >
                                            {tag.itemName},
                                          </span>
                                        );
                                      });
                                    })}
                                  </p>
                                  <div className="ratting_sec d-flex align-items-center justify-content-between">
                                    <div className="review_start">
                                      <Rating
                                        initialValue={item.averageReview}
                                        readonly
                                      />
                                    </div>
                                    <div className="heart-img">
                                      <img
                                        src="./assets/img/heart_gray.svg"
                                        alt="grat heart"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="grid_item_multiple">
                    <div className="restaurants_group">
                      {state &&
                        state.map((item, index) => {
                          return (
                            <Link
                              key={index}
                              state={{ item }}
                              className="restaurants_item"
                              to="/restaurantmenu"
                            >
                              <div className="res_img">
                                <img
                                  onError={placeholderHandler}
                                  src={item.restaurantImage}
                                  alt="restaurant"
                                  className="restaurant-img"
                                />
                              </div>
                              <div className="res_content">
                                <div className="my-2 d-flex align-items-center justify-content-between">
                                  <h6 className="head6">
                                    {item.restaurantName}
                                  </h6>
                                  <div className="btn_res open">Open</div>
                                </div>
                                <p className="txt my-2">
                                  {item.outlet.map((food) => {
                                    return food.foodTag.map((tag, index) => {
                                      return (
                                        <span
                                          className="me-2"
                                          key={"tag" + index}
                                        >
                                          {tag.itemName},
                                        </span>
                                      );
                                    });
                                  })}
                                </p>
                                <div className="ratting_sec d-flex align-items-center justify-content-between">
                                  <div className="review_start">
                                    <img
                                      src="./assets/img/review_star.svg"
                                      alt="start"
                                    />
                                  </div>
                                  <div className="heart-img">
                                    <img
                                      src="./assets/img/heart_gray.svg"
                                      alt="grat heart"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="text-center middle-line-RL mt-5">
                        <a href="restaurant" className="hoverbtn btnsize17 d-block m-auto">View More</a>
                    </div> */}
        </div>
      </section>
    </>
  );
};

export default Restaurant;
