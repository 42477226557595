export const CEHECK_ENTER_NUMBER = "CEHECK_ENTER_NUMBER"
export const FETCH_CUISINES = "FETCH_CUISINES"
export const LOGIN_DATA = "LOGIN_DATA"
export const ENTER_OTP = "ENTER_OTP"
export const SIGN_UP = "SIGN_UP"
export const USER_LOGIN = "USER_LOGIN"
export const RESTAURANT_LIST = "RESTAURANT_LIST"
export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
export const UPDATE_ONBOARDING_STATE = "UPDATE_ONBOARDING_STATE";